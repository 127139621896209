/* eslint-disable @next/next/no-img-element */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Layout from 'components/Layout';
import styles from 'styles/LandingPage.module.scss';
import 'swiper/swiper.min.css';
import Link from 'next/link';
import {
  BLOCKLENS_LOGIN_URL,
  LANDING_FEEDBACKS,
  NETWORKS_BLOCKLENS,
} from 'utils/constant';
import { NextSeoProps, ProductJsonLd } from 'next-seo';
import { productJsonLd, seoConfigs } from 'next-seo.config';
import {
  Block01,
  Block02,
  Block03,
  Block04,
  Circle01,
  Circle02,
  iconFeedbackImg,
  RubikDesktopStart,
  RubikDesktopEnd,
  RubikMobileStart,
  RubikMobileEnd,
} from 'public/images';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { Box, Flex, Text } from '@chakra-ui/react';
const { Fade, Zoom, Roll, Bounce, LightSpeed } = require('react-reveal');
import { useAnimationFrame } from 'utils/utils-hook';

const BannerHeader = () => {
  const timerRef = useRef<number>(0);

  const TIME_OFF_GIF_START = 3.6;
  const [showGifStart, setShowGifStart] = useState<boolean>(true);

  const callbackAnimate = (deltaTime: number) => {
    if (timerRef.current > TIME_OFF_GIF_START * 1000 + 10) {
      setShowGifStart(false);
      return;
    }
    timerRef.current += deltaTime;
  };

  useAnimationFrame(callbackAnimate);

  return (
    <>
      {/* start:: gif box desktop */}
      {showGifStart && (
        <Box
          w={'full'}
          height={'full'}
          pos={'absolute'}
          top={0}
          left={0}
          zIndex={1}
          display={{ base: 'none', lg: 'block' }}
        >
          <Image
            className={styles['gif-rubik']}
            src={RubikDesktopStart}
            alt="Rubik3D"
          />
        </Box>
      )}
      {timerRef.current > TIME_OFF_GIF_START * 1000 - 200 && (
        <Box
          w={'full'}
          height={'full'}
          pos={'absolute'}
          top={0}
          left={0}
          zIndex={1}
          display={{ base: 'none', lg: 'block' }}
        >
          <Image
            className={styles['gif-rubik']}
            src={RubikDesktopEnd}
            alt="Rubik3D"
          />
        </Box>
      )}
      {/* end:: gif box desktop */}

      {/* start:: gif box mobile */}
      {showGifStart && (
        <Box
          display={{ base: 'flex', lg: 'none' }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Image
            className={styles['gif-rubik']}
            src={RubikMobileStart}
            alt="Rubik3D"
          />
        </Box>
      )}
      {timerRef.current > TIME_OFF_GIF_START * 1000 - 200 && (
        <Box
          display={{ base: 'flex', lg: 'none' }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Image
            className={styles['gif-rubik']}
            src={RubikMobileEnd}
            alt="Rubik3D"
          />
        </Box>
      )}
      {/* end:: gif box mobile */}
    </>
  );
};

const Home = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  const _renderGetStarted = () => (
    <Box
      pos={'relative'}
      overflow={'hidden'}
      pt={{ base: '82px', lg: '100px' }}
      pb={{ base: '28px', lg: '37px' }}
      px={4}
      borderRadius={{ base: '0px 0px 100px 100px', lg: '0px 0px 250px 250px' }}
      bg={'#f0f7ff'}
    >
      <Box pos={'absolute'} top={0} left={0} right={0} bottom={0} zIndex={3}>
        <Image
          className={styles['block-rubik']}
          src={Block01}
          alt="Block"
          style={{ '--block-duration': '4s' } as any}
        />
        <Image
          className={styles['block-rubik']}
          src={Block02}
          alt="Block"
          style={{ '--block-duration': '4.3s' } as any}
        />
        <Image
          className={styles['block-rubik']}
          src={Block03}
          alt="Block"
          style={{ '--block-duration': '3.1s' } as any}
        />
        <Image
          className={styles['block-rubik']}
          src={Block04}
          alt="Block"
          style={{ '--block-duration': '4.5s' } as any}
        />
        <Image
          className={`${styles['circle-img']} ${styles['circle-01']}`}
          src={Circle01}
          alt="Circle"
        />
        <Image
          className={`${styles['circle-img']} ${styles['circle-02']}`}
          src={Circle02}
          alt="Circle"
        />
      </Box>
      <Box mx={'auto'} maxW={'1240px'}>
        <Box
          maxW={{ lg: '702px' }}
          pt={{ base: '40px', lg: '200px' }}
          pb={{ base: '20px', lg: '118px' }}
          pos={'relative'}
          zIndex={4}
        >
          <Box mb={{ base: '45px', lg: '50px' }}>
            <Fade bottom>
              <Text
                as={'h1'}
                mb={{ base: '20px', lg: '25px' }}
                className={styles['title-started']}
              >
                Your <Text as="span">best web3</Text> buddy On-chain data teller
                & interpreter
              </Text>
            </Fade>
            <Fade left delay={200}>
              <Text
                as={'span'}
                className={styles['subtext-started']}
                maxW={'449px'}
                display={'block'}
              >
                Decode raw Web 3 data & transform user journey across blockchain
                networks.
              </Text>
            </Fade>
          </Box>
          <Fade left delay={400}>
            <Link
              href={'https://console.blocklens.io/'}
              target="_blank"
              rel="noreferrer"
              title="Get Started"
              className={styles['btn-start-free']}
            >
              Get Started
            </Link>
          </Fade>
        </Box>

        {/* start:: banner */}
        <BannerHeader />
        {/* end:: banner */}

        <Flex
          flexDir={'column'}
          alignItems={'center'}
          pos={'relative'}
          mt={{ base: '-50px', lg: '40px' }}
          zIndex={2}
        >
          <Text as={'p'} className={styles['text-scroll-down']}>
            Scroll down to learn more
          </Text>
          <Box mt={'5px'}>
            <img
              className={styles['img-down-anim']}
              src="/icons/icon-arrow-down.svg"
              alt="icon-down"
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );

  const _renderAvailableAcross = () => (
    <Box
      mt={{ base: '65px', lg: '110px' }}
      mb={{ base: '70px', lg: '122px' }}
      px={4}
    >
      <Zoom>
        <Text
          as={'h2'}
          maxW={'523px'}
          mb={{ base: 0, lg: 20 }}
          className={styles['heading-text']}
        >
          <Text as={'span'}>Build Meaningful Stories </Text>
          With Your Data
        </Text>
      </Zoom>
      <Box
        display={{ lg: 'flex' }}
        alignItems={'center'}
        justifyContent={'center'}
        mt={{ base: '30px', lg: '16px' }}
      >
        <Box
          pr={{ lg: '25px' }}
          display={'flex'}
          flexDir={'column'}
          alignItems={'center'}
        >
          <Fade right>
            <img src="/images/TurnRawData.png" alt="TurnRawData" />
            <Zoom delay={500}>
              <Text mt={5} className={styles['caption-text']}>
                Turn raw data
              </Text>
            </Zoom>
          </Fade>
        </Box>

        <Box
          display={{ base: 'flex', lg: 'none' }}
          justifyContent={'center'}
          mt={5}
        >
          <Zoom delay={600} top cascade>
            <img src="/images/Arrow-down.png" alt="Arrow-down" />
          </Zoom>
        </Box>

        <Box display={{ base: 'none', lg: 'block' }}>
          <Zoom delay={600} left cascade>
            <img src="/images/ArrowRightOrange.png" alt="ArrowRightOrange" />
          </Zoom>
        </Box>

        <Box>
          <Box
            position={'relative'}
            width={'max-content'}
            margin={{ base: '70px auto 20px auto', lg: '0 20px' }}
          >
            <Fade right delay={1000}>
              <img src="/images/HexagonAcross.png" alt="HexagonAcross" />
            </Fade>
            <Box
              position={'absolute'}
              top={'-65px'}
              transform={'translateX(-50%)'}
              left={' 50%'}
            >
              <Link
                href={'/dashboard'}
                rel="noreferrer"
                title="Blocklens Dashboard"
              >
                <Roll top delay={1200}>
                  <img
                    src="/icons/icon-dashboard.png"
                    alt="Blocklens Dashboard"
                  />
                </Roll>
              </Link>
            </Box>
            <Box position={'absolute'} right={'-30px'} bottom={'0'}>
              <Link
                href={'/blocklens-api'}
                rel="noreferrer"
                title="Blocklens API"
              >
                <Roll right delay={1200}>
                  <img
                    src="/icons/icon-blocklens-api.png"
                    alt="Blocklens API"
                  />
                </Roll>
              </Link>
            </Box>
            <Box position={'absolute'} left={'-30px'} bottom={'0'}>
              <Link
                href={'/blocklens-trigger'}
                rel="noreferrer"
                title="Blocklens Trigger"
              >
                <Roll left delay={1200}>
                  <img
                    src="/icons/icon-blocklens-trigger.png"
                    alt="Blocklens Trigger"
                  />
                </Roll>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box
          display={{ base: 'flex', lg: 'none' }}
          justifyContent={'center'}
          mb={5}
        >
          <Zoom delay={1500} top cascade>
            <img src="/images/Arrow-down.png" alt="Arrow-down" />
          </Zoom>
        </Box>

        <Box display={{ base: 'none', lg: 'block' }}>
          <Zoom delay={1500} left cascade>
            <img src="/images/ArrowRightOrange.png" alt="ArrowRightOrange" />
          </Zoom>
        </Box>

        <Box
          pl={{ lg: '25px' }}
          display={'flex'}
          flexDir={'column'}
          alignItems={'center'}
        >
          <Fade right delay={2000}>
            <img src="/images/CastleAcross.png" alt="CastleAcross" />
            <Zoom delay={2500}>
              <Text mt={5} maxW={'207px'} className={styles['caption-text']}>
                Into stories that excites your audience
              </Text>
            </Zoom>
          </Fade>
        </Box>
      </Box>
    </Box>
  );

  const _renderBlockchainNetwork = () => (
    <Box pb={{ base: '65px', lg: '100px' }} px={4}>
      <Zoom>
        <Text as={'h2'} className={styles['heading-text']} maxW={'758px'}>
          Available Across <Text as={'span'}>Multi Blockchain Networks</Text>
        </Text>
      </Zoom>
      <Box mt={{ base: '30px', lg: '40px' }}>
        <Flex
          justify={'center'}
          gap={{ base: '15px', lg: '45px' }}
          mb={{ base: '15px', lg: '25px' }}
        >
          {NETWORKS_BLOCKLENS.available.map((url, index) => (
            <Roll
              left
              key={index}
              delay={400 * (NETWORKS_BLOCKLENS.available.length - index)}
            >
              <Box pos={'relative'}>
                <img src="/icons/icon-hexagon.svg" alt="" />
                <Flex
                  alignItems={'center'}
                  justify={'center'}
                  pos={'absolute'}
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  px={3}
                  py={4}
                >
                  <img src={url} alt="network" style={{ maxHeight: '100%' }} />
                </Flex>
              </Box>
            </Roll>
          ))}
        </Flex>
        <Flex
          justify={'center'}
          flexWrap={'wrap'}
          gap={{ base: '15px', lg: '45px' }}
        >
          {NETWORKS_BLOCKLENS.comming.map((url, index) => (
            <Box key={index} maxW={{ base: '22.2%' }}>
              <Fade
                right
                delay={400 * NETWORKS_BLOCKLENS.available.length + 400 * index}
              >
                <Box key={url} pos={'relative'}>
                  <Box opacity={0.2}>
                    <img src="/icons/icon-hexagon.svg" alt="" />
                  </Box>
                  <Flex
                    alignItems={'center'}
                    justify={'center'}
                    pos={'absolute'}
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    opacity={0.2}
                    px={3}
                    py={4}
                  >
                    <img
                      style={{ maxHeight: '100%' }}
                      src={url}
                      alt="network"
                    />
                  </Flex>
                  <Flex
                    alignItems={'center'}
                    justify={'center'}
                    pos={'absolute'}
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                  >
                    <img src={'/icons/comingsoon.svg'} alt="coming-soon" />
                  </Flex>
                </Box>
              </Fade>
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );

  const _renderFeedback = () => (
    <div className={styles['feedback']}>
      <div className={styles['feedback__content-width']}>
        <div className={styles['feedback__heading']}>
          <LightSpeed>
            <Image src={iconFeedbackImg} alt="icon" />
          </LightSpeed>
          <Zoom>
            <Text as={'h2'} maxW={'710px'} className={styles['heading-text']}>
              <Text as={'span'}>Our Enriched </Text>
              Web3 Data Transforms Businesses
            </Text>
          </Zoom>
        </div>
        <Swiper
          spaceBetween={50}
          navigation={true}
          pagination={true}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          modules={[Pagination, Navigation]}
        >
          {LANDING_FEEDBACKS.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Bounce>
                  <div className={styles['feedback__item']} key={index}>
                    <div className={styles['feedback__description']}>
                      {`" ${item.description}"`}
                    </div>
                    <div>
                      <div className={styles['feedback__name']}>
                        {item.name}
                      </div>
                      <div className={styles['feedback__title']}>
                        {item.title}
                      </div>
                    </div>
                  </div>
                </Bounce>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );

  return (
    <Box className={styles['landingpage']} opacity={isLoading ? 1 : 0}>
      <Box className={styles['main-landing']}>
        <Box className={styles['introduction']}>
          {_renderGetStarted()}
          {_renderAvailableAcross()}
          {_renderBlockchainNetwork()}
          {_renderFeedback()}
        </Box>
      </Box>
    </Box>
  );
};

export default Home;

Home.getLayout = function getLayout(page: ReactElement) {
  const seoProps: NextSeoProps = {
    title: `${seoConfigs.title} - Notifications for Web3.0 Developers`,
  };
  return (
    <>
      <Layout {...seoProps} className={styles['landing-layout']}>
        <ProductJsonLd {...productJsonLd} />
        {page}
      </Layout>
    </>
  );
};
